import { APITransaction, APITransactionListItem } from "types/API/Transaction";

import Connection from "./Connection";
import Mock from "./Mock";
import { MOCK_BUY_TRANSACTION, MOCK_TRANSACTIONS } from "./mocks/mock-transaction";

// const getTransactions = (): Promise<APITransactionListItem[]> => Connection.get(`/organizations/transactions`);

const getTransactions = (): Promise<APITransactionListItem[]> =>
  Mock.mockRequest(`/organizations/transactions`, MOCK_TRANSACTIONS);

// const getTransaction = (transactionId: string) => Connection.get(`/transaction/${transactionId}`);

const getTransaction = (transactionId: string) =>
  Mock.mockRequest(`/transaction/${transactionId}`, {
    ...MOCK_BUY_TRANSACTION,
    Status:
      transactionId === "1"
        ? "PENDING"
        : transactionId === "2"
        ? "CONFIRMED"
        : transactionId === "3"
        ? "AWAITING_CONFIRMATION"
        : "CONFIRMED",
    Id: transactionId,
  }) as Promise<APITransaction>;

interface APICreateBuyTransaction {
  wallet: string;
  amount: number;
  reference: string;
}

// const createBuyTransaction = (options: APICreateBuyTransaction) =>
//   Connection.post(`/transaction/`, { ...options, Type: "Buy" });

const createBuyTransaction = (_: APICreateBuyTransaction) =>
  Mock.mockRequest(`/transaction`, undefined) as Promise<undefined>;

interface APICreateSellTransaction {
  wallet: string;
  amount: number;
  reference: string;
}

// const createSellTransaction = (options: APICreateSellTransaction) =>
//   Connection.post(`/transaction/`, { ...options, Type: "Sell" });

const createSellTransaction = (_: APICreateSellTransaction) =>
  Mock.mockRequest(`/transaction`, undefined) as Promise<undefined>;

export interface APIPostBuyOrder {
  WalletAddress: string;
  FiatAmount: string;
}

export interface APIPostBuyOrderResponse {
  Entries: {
    EventId: string;
  }[];
  FailedEntryCount: number;
}

const postBuyOrder = (buyPayload: APIPostBuyOrder) =>
  Connection.post("/organizations/buystablr", buyPayload) as Promise<APIPostBuyOrderResponse>;

// const postBuyOrder = (_: APIPostBuyOrder) =>
//   Mock.mockRequest(`/transaction`, MOCK_BUY_ORDER) as Promise<APIPostBuyOrderResponse>;

interface APIPostConfirmTransactionResponse {
  success: true;
}

// const confirmTransaction = (transactionId: string, transactionHash: string) =>
//   Connection.post(`/transaction/${transactionId}/confirm`, { transactionHash }) as Promise<APIPostConfirmTransactionResponse>;

const confirmTransaction = (transactionId: string, _: string) =>
  Mock.mockRequest(`/transaction/${transactionId}/confirm`, {
    success: true,
  }) as Promise<APIPostConfirmTransactionResponse>;

export default {
  getTransactions,
  getTransaction,
  createBuyTransaction,
  createSellTransaction,
  postBuyOrder,
  confirmTransaction,
};
