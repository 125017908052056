import { ReactComponent as EmailIcon } from "assets/icons/alternate-email.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/lock.svg";
import { Auth } from "aws-amplify";
import Button from "components/atoms/Button/Button";
import InputLink from "components/atoms/Form/InputLink/InputLink";
import Heading from "components/atoms/Heading/Heading";
import Paragraph from "components/atoms/Paragraph/Paragraph";
import ErrorMessage from "components/molecules/Form/ErrorMessage/ErrorMessage";
import PasswordInput from "components/molecules/Form/PasswordInput/PasswordInput";
import TextInput from "components/molecules/Form/TextInput/TextInput";
import { FORM_ERROR } from "final-form";
import composeTestID from "functions/compose-test-id";
import getErrorMessage from "functions/get-error-message";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import HalfHalfResponsive from "templates/HalfHalfResponsive/HalfHalfResponsive";
import { AmplifyUserObject } from "types/amplify/AmplifyUserObject";

interface SignInProps {
  testID?: string;
  onComplete: (user: AmplifyUserObject) => void;
}

interface SignInFormValues {
  email: string;
  password: string;
}

const SignInStyled = styled.main`
  width: 100%;
  min-height: 100%;
`;

SignInComponent.testID = "signIn";

export default function SignInComponent({ onComplete, ...props }: SignInProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnSubmit = async ({ email, password }: SignInFormValues) => {
    setIsLoading(true);
    try {
      const user = await Auth.signIn(email, password);

      onComplete(user);

      // Handle TFA and New Password redirection challenges
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate("./new-password");
        return;
      } else if (user.challengeName === "SMS_MFA") {
        navigate("./sms-authentication");
        return;
      }
      // Otherwise, log in
      navigate("../dashboard");
    } catch (error) {
      setIsLoading(false);

      return { [FORM_ERROR]: getErrorMessage(error) };
    }
    setIsLoading(false);
  };

  return (
    <SignInStyled data-testid={composeTestID(SignInComponent.testID, props.testID)}>
      <HalfHalfResponsive>
        <Form onSubmit={handleOnSubmit}>
          {({ handleSubmit, submitError }) => (
            <>
              <Heading as="h1" varient="h2">
                Hi, Welcome Back!
              </Heading>
              <Paragraph>Easy access to fully backed digital assets</Paragraph>
              <Field
                name="email"
                label="Email Address"
                icon={<EmailIcon />}
                component={TextInput}
                placeholder="email@stablr.com"
              ></Field>
              <Field
                name="password"
                label="Password"
                icon={<PasswordIcon />}
                component={PasswordInput}
                placeholder="Password"
                appendElement={<InputLink to="/sign-in/reset-password">Forgot password?</InputLink>}
              ></Field>
              <ErrorMessage>{submitError}</ErrorMessage>
              <Button loading={isLoading} width="100%" onClick={handleSubmit}>
                Login
              </Button>
            </>
          )}
        </Form>
      </HalfHalfResponsive>
    </SignInStyled>
  );
}
