export default {
  gradient: "linear-gradient(90.48deg, #849DFF 0.42%, #B898FF 99.6%)",
  primary: "#849DFF",
  primaryLight: "#F3F5FF",
  secondary: "#B898FF",
  grey4: "#CCCCCC",
  grey3: "#E4E4E4",
  grey2: "#F1F1F1",
  grey1: "#FAF8F9",
  greyDark1: "#969696",
  white: "#fff",
  black: "#000000",
  red: "#FF5B42",
  lightRed: "#FFD6D0",
  yellow: "#EDE325",
  green: "#00CE59",
  lightGreen: "rgba(0, 206, 89, 0.25)",
  purple: "#849DFF",
  lightPurple: "#849DFF1A",
  transparent: "transparent",
  orange: "#FF8E08",
  lightOrange: "rgba(237, 145, 37, 0.25)",
  borderGradient: "rgba(0, 0, 0, 0.1)",
  blue: "#2B8AFF",
  lightPurple1: "#849DFF33",
};
