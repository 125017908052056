import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import UserDataContextProvider from "../context/UserDataProvider";
import AuthenticationWrapper from "./AuthenticationWrapper";
import Dashboard from "./Dashboard";
import DashboardMenu from "./DashboardMenu";
// import Home from "./Home";
import SignIn from "./SignIn";
import Test from "./Test";

export default function PageRoutes() {
  return (
    <BrowserRouter>
      <UserDataContextProvider>
        <AuthenticationWrapper>
          {isAuthenticatedPath =>
            isAuthenticatedPath ? (
              <DashboardMenu>
                <RoutesComponent />
              </DashboardMenu>
            ) : (
              <RoutesComponent />
            )
          }
        </AuthenticationWrapper>
      </UserDataContextProvider>
    </BrowserRouter>
  );
}

function RoutesComponent() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/sign-in/*" element={<SignIn />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/test/*" element={<Test />} />
    </Routes>
  );
}
