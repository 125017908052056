import * as React from "react";
import styled from "styled-components";
import colorStyle from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";

interface CardDataProps {
  label: string;
  data: string;
  color?: string;
}

const CardDataStyled = styled.div``;

const CardDataLabelStyled = styled.span(
  ({ $color }: { $color: string }) => `
  display: block;
  color: ${$color};
  font-family: ${fontFamily.secondary};
  font-weight: ${fontWeight.light};
  font-size: ${fontSize.p};
`
);

const CardDataDataStyled = styled.span(
  ({ $color }: { $color: string }) => `
    display: block;
    color: ${$color};
    font-family: ${fontFamily.secondary};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.h3};
  `
);

export default function CardDataComponent({ label, data, color = colorStyle.black }: CardDataProps) {
  return (
    <CardDataStyled>
      <CardDataLabelStyled $color={color}>{label}</CardDataLabelStyled>
      <CardDataDataStyled $color={color}>{data}</CardDataDataStyled>
    </CardDataStyled>
  );
}
