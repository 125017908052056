import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import React, { ReactElement } from "react";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import color from "styles/color";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

import ButtonComponent from "../Button/Button";
import IconStyled from "../Icon/Icon";
import Paragraph from "../Paragraph/Paragraph";

type ToastType = "error" | "success" | "info";

const ToastStyled = styled.div<{ visible: boolean }>`
  animation: ${({ visible }) => (visible ? "custom-enter 1s ease" : "custom-exit 1s ease")};
  padding: ${spacing.s} ${spacing.m};
  background-color: ${color.white};
  border-radius: 10px;
  min-width: 300px;
  max-width: 500px;
  box-shadow: 5px 2px 10px ${color.grey3};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderStyled = styled.div(
  ({ type }: { type: ToastType }) => `
  display: flex;

  & > span {
    margin-left: ${spacing.s};

    & > h5 {
      margin: 0;
      font-weight: ${fontWeight.semiBold};
      color: ${color.greyDark1};
    }

    & > p {
      color: ${type === "error" ? color.red : type === "success" ? color.green : color.blue};
      font-weight: ${fontWeight.semiBold};
      margin: 0;
    }
  }
`
);

export default function Notifications() {
  const NOTIFOCATION_TYPE: { [key: string]: { color: string; icon: ReactElement } } = {
    success: {
      color: color.green,
      icon: <CheckIcon />,
    },
    error: {
      color: color.red,
      icon: <WarningIcon />,
    },
    info: {
      color: color.blue,
      icon: <InfoIcon />,
    },
  };

  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        duration: 3000,
      }}
    >
      {({ id, visible, type, ariaProps, message }: any) => {
        const notification = NOTIFOCATION_TYPE[type] || NOTIFOCATION_TYPE.info;
        return (
          <ToastStyled visible={visible}>
            <HeaderStyled type={type as ToastType}>
              <IconStyled size={28} color={notification.color}>
                {notification.icon}
              </IconStyled>
              <span>
                <h5>{ariaProps?.title}</h5>
                <Paragraph>{message?.toString()}</Paragraph>
              </span>
            </HeaderStyled>
            <ButtonComponent
              onClick={() => toast.remove(id)}
              icon={<CloseIcon />}
              iconSize={20}
              varient="secondary"
              layout="icon-only"
            />
          </ToastStyled>
        );
      }}
    </Toaster>
  );
}
