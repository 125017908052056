import { ReactComponent as LogoIconSVG } from "assets/icons/logo.svg";
import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface NavigationDrawerProps {
  testID?: string;
  children?: React.ReactElement | React.ReactElement[];
}

const NavigationDrawerStyled = styled.nav`
  width: 125px;
  height: 100%;
  background-color: #f6f3f8;
  padding: ${spacing.m} 0;
`;

const NavigationDrawerLogoStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${spacing.s};
  margin-bottom: ${spacing.xl};
`;

const NavigationDrawerListStyled = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
`;

const LogoContainerStyled = styled.div`
  position: relative;
`;

const LogoTextStyled = styled.span`
  position: absolute;
  left: 100%;
  top: 0;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.p};
  font-family: ${fontFamily.secondary};
`;

NavigationDrawerComponent.testID = "navigationDrawer";

export default function NavigationDrawerComponent({ children, ...props }: NavigationDrawerProps) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <></>
  ) : (
    <NavigationDrawerStyled data-testid={composeTestID(NavigationDrawerComponent.testID, props.testID)}>
      <NavigationDrawerLogoStyled>
        <LogoContainerStyled as={Icon} size={"20px"} gradient>
          <LogoIconSVG />
          <LogoTextStyled>OPS</LogoTextStyled>
        </LogoContainerStyled>
      </NavigationDrawerLogoStyled>
      <NavigationDrawerListStyled>{children}</NavigationDrawerListStyled>
    </NavigationDrawerStyled>
  );
}
