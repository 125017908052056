import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import Badge from "components/atoms/Badge";
import TableBody from "components/atoms/Table/TableBody";
import TableData from "components/atoms/Table/TableData/TableData";
import TableDataCurrency from "components/atoms/Table/TableDataCurrency";
import TableHead from "components/atoms/Table/TableHead";
import TableHeader from "components/atoms/Table/TableHeader/TableHeader";
import TableRow from "components/atoms/Table/TableRow";
import TableRowBadge from "components/atoms/Table/TableRowBadge";
import capitalize from "functions/capitalize";
import { useIsMobile } from "hooks/use-is-mobile";
import * as React from "react";
import styled from "styled-components";
import spacing from "styles/spacing";
import { AppTransactionListItem } from "types/App/Transaction";

interface ExampeTableProps {
  data: AppTransactionListItem[];
}

const ExampeTableStyled = styled.table`
  width: 100%;

  & .currency {
    & > p {
      margin-top: ${spacing.xxs};
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;

const ExampeTableWrapperStyled = styled.div`
  width: 100%;
  padding: 0 ${spacing.m};
  overflow-x: auto;
`;

const columnHelper = createColumnHelper<AppTransactionListItem>();

const columns = [
  columnHelper.accessor("Type", {
    header: "Type",
    cell: info => <b>{capitalize(info.getValue())}</b>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("DigitalAmount", {
    header: "Amount",
    cell: info => <TableDataCurrency digitalAmount={info.getValue()} fiatAmount={info.row.original.FiatAmount} />,
    footer: info => info.column.id,
  }),
  columnHelper.accessor("CreatedAt", {
    header: "Date",
    cell: info => new Date(info.getValue()).toLocaleDateString(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("OrderReference", {
    header: "Client Reference",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("WalletAddress", {
    header: "Wallet",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Id", {
    header: "ID",
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor("Status", {
    header: "Status",
    cell: info => (info.getValue()?.trim() ? <Badge varient={"default"} text={info.getValue()}></Badge> : undefined),
    footer: info => info.column.id,
  }),
];

const mobileColumns = [columns[0], columns[1], columns[2]];

export default function ExampeTableComponent({ data }: ExampeTableProps) {
  const isMobile = useIsMobile();

  const table = useReactTable({
    data,
    columns: isMobile ? mobileColumns : columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <ExampeTableWrapperStyled>
      <ExampeTableStyled cellSpacing="0">
        <TableHead>
          <>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    paddingSize="small"
                    key={header.id}
                    textAlign={header.column.columnDef.header === "Amount" ? "right" : "left"}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </>
        </TableHead>
        <TableBody>
          <>
            {table.getRowModel().rows.map(row => {
              return (
                <React.Fragment key={row.id}>
                  {isMobile && (
                    <TableRowBadge
                      colSpan={4}
                      badgeElement={<Badge varient={"default"} text={row.original?.Status} />}
                    ></TableRowBadge>
                  )}
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <TableData paddingSize="small" key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableData>
                      );
                    })}
                  </TableRow>
                </React.Fragment>
              );
            })}
          </>
        </TableBody>
      </ExampeTableStyled>
    </ExampeTableWrapperStyled>
  );
}
