import api from "api";
import { APIDashboard } from "types/API/Dashboard";

import { MOCK_DASHBOARD } from "./mocks/mock-dashboard";

// import Connection from "./Connection";

// const getDashboard = (): Promise<APICompanyInfo> => Connection.get("/dashboard");

const getDashboard = (): Promise<APIDashboard> => api.Mock.mockRequest("/dashboard", MOCK_DASHBOARD);

export default {
  getDashboard,
};
