const apiName = "stablr-api";

export default {
  apiName,
  environment: process.env.REACT_APP_ENV,
  fallbackDenominator: ",",
  links: {
    privacyPolicy: "https://www.stablr.com/privacy-policy",
    termsOfUse: "https://www.stablr.com/terms-of-use",
  },
  routes: {
    authenticated: [{ path: "/dashboard/*" }, { path: "/treasury/*" }, { path: "/transactions/*" }], // All routes that are within the app must be included here to prevent being redirected
  },
  awsEnv: {
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    API: {
      endpoints: [
        {
          name: apiName,
          endpoint: process.env.REACT_APP_BASE_URL,
          custom_headers: () => ({
            "Content-Type": "application/json",
          }),
        },
      ],
    },
  },
};
