import { APICreateUser, APIUpdateUser, APIUserListItem } from "types/API/User";

import Connection from "./Connection";

const getUserList = (): Promise<APIUserListItem[]> => Connection.get("/users");

const getUser = (userId: string): Promise<APIUserListItem> => Connection.get(`/users/${userId}`);

const createUser = (user: APICreateUser) => Connection.postSocket("/users", user);

const updateUser = (userId: string, user: APIUpdateUser) => Connection.putSocket(`/users/${userId}`, user);

const deleteUser = (userId: string) => Connection.deleteSocket(`/users/${userId}`);

const approveUser = (userId: string, data: object): Promise<boolean> =>
  Connection.post(`/users/${userId}/approve`, data);

const rejectUser = (userId: string, data: object): Promise<boolean> => Connection.post(`/users/${userId}/reject`, data);

export default {
  getUserList,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  approveUser,
  rejectUser,
};
