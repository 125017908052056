import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import fontFamily from "styles/fontFamily";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface UserDropDownFootLinksProps {
  links: { to: string; label: string; external?: boolean }[];
}

const UserDropDownFootLinksStyled = styled.div`
  display: flex;
  margin: ${spacing.m} 0;
`;

const FootLinkStyled = styled.a`
  color: ${color.black};
  font-size: ${fontSize.small};
  font-family: ${fontFamily.primary};
  font-weight: ${fontWeight.regular};
  text-decoration: underline;
  padding: 0 ${spacing.xs};
`;

export default function UserDropDownFootLinksComponent({ links }: UserDropDownFootLinksProps) {
  return (
    <UserDropDownFootLinksStyled>
      {links.map(({ to, label, external }) =>
        external === true ? (
          <FootLinkStyled key={to} href={to} target="_blank">
            {label}
          </FootLinkStyled>
        ) : (
          <FootLinkStyled as={Link} key={to} to={to}>
            {label}
          </FootLinkStyled>
        )
      )}
    </UserDropDownFootLinksStyled>
  );
}
