import Icon from "components/atoms/Icon/Icon";
import composeTestID from "functions/compose-test-id";
import * as React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import color from "styles/color";
import fontSize from "styles/fontSize";
import fontWeight from "styles/fontWeight";
import spacing from "styles/spacing";

interface AppDesktopNavigationLinkProps {
  testID?: string;
  children?: string;
  to: string;
  icon: React.ReactElement;
}

const LINK_PADDING = spacing.xs;
const ICON_CIRCLE_SIZE = "34px";

const AppDesktopNavigationLinkContainerStyled = styled.li`
  width: 100%;
  margin: ${spacing.m} 0;
`;

const AppDesktopNavigationLinkStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: ${LINK_PADDING} 0;
  text-decoration: inherit;

  &:visited,
  &:active {
    text-decoration: inherit; /* no underline */
  }

  &.active {
    &::before {
      content: "";
      width: 4px;
      display: block;
      background-color: ${color.primary};
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 4px;
    }

    & svg > path {
      fill: url(#gradient) !important;
    }

    & span {
      color: ${color.black};
    }
  }

  &:hover {
    & svg > path {
      fill: url(#gradient) !important;
    }

    & span {
      color: ${color.black};
    }
  }
`;

const AppDesktopNavigationLinkIconStyled = styled.div`
  width: ${ICON_CIRCLE_SIZE};
  min-height: ${ICON_CIRCLE_SIZE};
  border-radius: 100%;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.025);
  background-color: ${color.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppDesktopNavigationLinkTextStyled = styled.span`
  font-size: ${fontSize.p};
  font-weight: ${fontWeight.semiBold};
  margin: ${spacing.s} 0 0 0;
  color: ${color.greyDark1};
`;

AppDesktopNavigationLinkComponent.testID = "appDesktopNavigationLink";

export default function AppDesktopNavigationLinkComponent({
  icon,
  to,
  children,
  ...props
}: AppDesktopNavigationLinkProps) {
  return (
    <AppDesktopNavigationLinkContainerStyled>
      <AppDesktopNavigationLinkStyled
        as={NavLink}
        to={to}
        {...props}
        data-testid={composeTestID(AppDesktopNavigationLinkComponent.testID, props.testID)}
      >
        <AppDesktopNavigationLinkIconStyled>
          <Icon size={18}>{icon}</Icon>
        </AppDesktopNavigationLinkIconStyled>
        <AppDesktopNavigationLinkTextStyled>{children}</AppDesktopNavigationLinkTextStyled>
      </AppDesktopNavigationLinkStyled>
    </AppDesktopNavigationLinkContainerStyled>
  );
}
