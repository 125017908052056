import { ReactComponent as DashboardIconSVG } from "assets/icons/dashboard.svg";
import { ReactComponent as PieChartIconSVG } from "assets/icons/pie-chart.svg";
import { ReactComponent as TransactionsIconSVG } from "assets/icons/transactions.svg";
import { ReactComponent as StablRLogoSVG } from "assets/svg/logo-stablr-ops.svg";
import { Auth } from "aws-amplify";
import Constants from "constants/index";
import useUserData from "hooks/context/use-user-data";
import { useIsMobile } from "hooks/use-is-mobile";
import DrawerLink from "pages/components/DrawerLink";
import HeaderNavigation from "pages/components/HeaderNavigation";
import NavigationDrawer from "pages/components/NavigationDrawer";
import UserDropDown from "pages/components/UserDropDown";
import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import DashboardTemplate from "templates/DashboardTemplate/DashboardTemplate";

import PackageJson from "../../package.json";
import MenuDropDown from "./components/MenuDropDown";
import MenuDropDownLink from "./components/MenuDropDownLink";
import MenuDropDownList from "./components/MenuDropDownList";
import UserDropDownActions from "./components/UserDropDownActions";
import UserDropDownDivider from "./components/UserDropDownDivider";
import UserDropDownFootLinks from "./components/UserDropDownFootLinks";
import UserDropDownInfo from "./components/UserDropDownInfo";

interface DashboardMenuProps {
  children: React.ReactElement;
}

export default function DashboardMenuComponent({ children }: DashboardMenuProps) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userData = useUserData();

  const handleOnLogOut = async () => {
    await Auth.signOut();
    navigate("../sign-in");
  };

  return (
    <DashboardTemplate
      navigationBarElement={
        <NavigationDrawer>
          <DrawerLink icon={<DashboardIconSVG />} to="/dashboard">
            Dashboard
          </DrawerLink>
          <DrawerLink icon={<PieChartIconSVG />} to="/treasury">
            Treasury
          </DrawerLink>
          <DrawerLink icon={<TransactionsIconSVG />} to="/transactions">
            Transactions
          </DrawerLink>
        </NavigationDrawer>
      }
      headerNavigationElement={
        <HeaderNavigation
          title={
            isMobile ? (
              <StablRLogoSVG />
            ) : (
              <Routes>
                <Route path="/dashboard/*" element={<>Dashboard</>} />
                <Route path="/treasury/*" element={<>Treasury</>} />
                <Route path="/transactions/*" element={<>Transactions</>} />
              </Routes>
            )
          }
        >
          <>
            <UserDropDown name={userData.name ?? "-"}>
              <UserDropDownInfo name={userData.name ?? "-"} email={userData.email ?? ""}></UserDropDownInfo>
              <UserDropDownDivider />
              <UserDropDownActions onLogout={handleOnLogOut} version={PackageJson.version} />
              <UserDropDownDivider />
              <UserDropDownFootLinks
                links={[
                  { to: Constants.links.termsOfUse, label: "Terms of Use" },
                  { to: "https://stablr.com", label: "StablR.com", external: true },
                  { to: Constants.links.privacyPolicy, label: "Privacy Policy" },
                ]}
              />
            </UserDropDown>

            {isMobile ? (
              <MenuDropDown>
                <>
                  <MenuDropDownList>
                    <MenuDropDownLink icon={<DashboardIconSVG />} to="/dashboard">
                      Dashboard
                    </MenuDropDownLink>
                    <MenuDropDownLink icon={<PieChartIconSVG />} to="/treasury">
                      Treasury
                    </MenuDropDownLink>
                    <MenuDropDownLink icon={<TransactionsIconSVG />} to="/transactions">
                      Transactions
                    </MenuDropDownLink>
                  </MenuDropDownList>
                </>
              </MenuDropDown>
            ) : (
              <></>
            )}
          </>
        </HeaderNavigation>
      }
    >
      {children}
    </DashboardTemplate>
  );
}
